






























































.fullscreen {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  pointer-events: none;
}

.tips {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(4px);
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2);
  bottom: 0;
  width: 100%;
  font-size: 0.9rem;
  padding: 0.5rem;
}

.tips.ios {
  padding-bottom: 26px;
}
