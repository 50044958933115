


























































































































































































































.tournament-team-dialog {
  width: 10rem;
  height: 10rem;
  background-color: white;
}
